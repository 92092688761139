import api from "@/base/utils/request";
import fetchFile from "@/base/utils/download";
// 获取所属架构/职务列表
export const positionLevel = data => {
  return api({
    url: "/admin/cyc/position/positionLevel",
    method: "post",
    data
  });
};
export const exportMyInvite= data => {
  return fetchFile({
    url: "/admin/cyc/user_view/exportMyInvite",
    method: "post",
    data,
    download: true
  });
};
// 列表形式展示所有上级组织接口
export const parentToList = (data, notCancel = true) => {
  return api({
    url: '/admin/cyc/Organization/parentToList',
    method: 'post',
    data,
    notCancel
  })
}