var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-page"},[_c('list-layout',{ref:"listLayout",attrs:{"filter-form":_vm.filterForm,"thead":_vm.thead,"tab-arr":_vm.tabArr,"on-fetch":_vm.getList},on:{"command":_vm.onCommand},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('user-withdraw-check-list-filter',{attrs:{"filterForm":_vm.filterForm,"uploadFilter":_vm.refreshList,"export-api":_vm.exportWithdrawCheckList}})]},proxy:true},{key:"tabletop",fn:function(){return undefined},proxy:true},{key:"item_user.id",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.user ? row.user.id : '--'))]}},{key:"item_user.avatar",fn:function(ref){
var row = ref.row;
return [_c('el-avatar',{attrs:{"src":_vm.fixImageUrl(row.user ? row.user.avatar : '', 300)}})]}},{key:"item_user.nickname",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.user ? (row.user.nickname || '--') : '--'))]}},{key:"item_user.phone",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.user ? (row.user.phone || '--') : '--'))]}},{key:"item_user.title_name",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.user ? (row.user.title_name || '--') : '--'))]}},{key:"item_price",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"order_price"},[_c('div',{staticClass:"price"},[_vm._v("￥"+_vm._s(row.price.toFixed(2)))])])]}},{key:"item_apply_refund",fn:function(ref){
var row = ref.row;
return [_c('list-status-text',{attrs:{"type":row.apply_refund == 1
            ? 'warning'
            : row.apply_refund == 2
            ? 'success'
            : row.apply_refund == 3
            ? 'danger'
            : 'info',"text":row.apply_refund == 1 ? '待商家受理' : row.apply_refund_text}})]}},{key:"top",fn:function(){return undefined},proxy:true},{key:"operation",fn:function(scope){return [(scope.row.apply_refund == 1)?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.agreeRow(scope.row)}}},[_vm._v("同意")]):_vm._e(),(scope.row.apply_refund == 1)?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.refuseRow(scope.row)}}},[_vm._v("拒绝")]):_vm._e(),_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.editRow(scope.row)}}},[_vm._v("详情")])]}}])}),_c('common-uncheck-dialog',{attrs:{"edit-data":_vm.checkDialog.data,"complete":_vm.refreshList,"submitApi":_vm.uncheckRetailWithdraw},model:{value:(_vm.checkDialog.show),callback:function ($$v) {_vm.$set(_vm.checkDialog, "show", $$v)},expression:"checkDialog.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }