import api from '@/base/utils/request'
import fetchFile from '@/base/utils/download'

export const withdrawCheckList = (data) => {
  return api({url:'/admin/retail/withdrawCheck/index', method: 'post', data})
}

export const exportWithdrawCheckList = (data) => {
  return fetchFile({
    url: '/admin/retail/withdrawCheck/exportIndex',
    method: 'post',
    data,
    download: true,
  })
}

export const uncheckRetailWithdraw = (data) => {
  return api({url:'/admin/retail/withdrawCheck/uncheck', method: 'post', data})
}

export const checkRetailWithdraw = (data) => {
  return api({url:'/admin/retail/withdrawCheck/check', method: 'post', data})
}