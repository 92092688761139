<template>
  <LayoutFilter
    :onFilter="ok"
    :onReset="resetForm"
    :toggleCollapseEnable="false"
    :onExport="exportApi ? onExport : null"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="templateForm"
      label-width="80px"
      size="medium"
      class="filter-from grid"
    >
      <el-form-item label="会员信息：" prop="user_name">
        <el-input v-model="form.user_name" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="手机号：" prop="phone">
        <el-input v-model="form.phone" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="会内职务：" prop="positionIds">
        <el-cascader
          v-model="form.positionIds"
          :options="OrganizationList"
          :props="props"
          placeholder="架构/会内职务"
          filterable
          clearable
        >
        </el-cascader>
      </el-form-item>
      <el-form-item label="审核状态：" prop="status">
        <el-select v-model="form.status" placeholder="请选择">
          <el-option label="全部" :value="-1"></el-option>
          <el-option
            v-for="item in checkStatusOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="提现金额：" prop="min_price">
        <input type="hidden" v-model="filterForm.min_price" />
        <el-select v-model="orderPriceRange" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option
            v-for="item in moneyOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item style="display: none" label="提现金额：" prop="max_price">
        <input type="hidden" v-model="filterForm.max_price" />
      </el-form-item>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from '@/base/layout/LayoutFilter'
import DatePeriodPicker from '@/base/components/Form/DatePeriodPicker'
import { parentToList } from '../../invite-members/api/benefits/member-list'

export default {
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    exportApi: Function,
  },
  data() {
    return {
      rules: {},
      // 联级选择配置
      props: {
        value: 'id',
        label: 'name',
        expandTrigger: 'hover',
        children: 'position',
      },
      // 架构选择列表
      OrganizationList: [],
      checkStatusOptions: [
        {
          id: 0,
          name: '审核中',
        },
        {
          id: 1,
          name: '通过',
        },
        {
          id: 2,
          name: '不通过',
        },
      ],
      moneyOptions: [],
    }
  },
  computed: {
    form: {
      get() {
        return this.filterForm
      },
      set(val) {},
    },
    orderPriceRange: {
      get() {
        if (this.form.min_price === -1) return ''
        return `${this.form.min_price}—${this.form.max_price}`
      },
      set(val) {
        if (val) {
          const [min, max] = val.split('—')
          this.form.min_price = Number(min)
          this.form.max_price = Number(max)
        } else {
          this.form.min_price = -1
          this.form.max_price = -1
        }
      },
    },
  },
  created() {
    this.getParentToList()

    // step 为 50
    const step = 50
    this.moneyOptions = Array.from(
      Array(10),
      (v, k) => `${k * step}—${(k + 1) * step}`
    )
    this.moneyOptions.push('500以上')
  },
  methods: {
    getParentToList() {
      parentToList({ crr_level: -1, is_show_all: 1 })
        .then((res) => {
          const { data } = res
          data.map((item) => {
            item.children = []
          })
          this.OrganizationList = data
        })
        .catch((err) => {})
    },
    onExport() {
      const postData = { ...this.form }
      if (this.exportApi) return this.exportApi(postData)
      return null
    },
    // 重置表单
    resetForm() {
      this.$refs['templateForm'].resetFields()
      this.uploadFilter(this.form)
    },
    // 筛选
    ok() {
      this.uploadFilter(this.form)
    },
  },
}
</script>

<style lang="scss" scoped>
.price-box {
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    margin: 0 5px;
  }
}
</style>
