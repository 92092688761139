<template>
  <div class="list-page">
    <list-layout
      ref="listLayout"
      :filter-form="filterForm"
      :thead="thead"
      :tab-arr="tabArr"
      :on-fetch="getList"
      @command="onCommand"
    >
      <template v-slot:filter>
        <user-withdraw-check-list-filter
          :filterForm="filterForm"
          :uploadFilter="refreshList"
          :export-api="exportWithdrawCheckList"
        ></user-withdraw-check-list-filter>
      </template>
      <template #tabletop></template>
      <template #item_user.id="{ row }">{{
        row.user ? row.user.id : '--'
      }}</template>
      <template #item_user.avatar="{ row }">
        <el-avatar
          :src="fixImageUrl(row.user ? row.user.avatar : '', 300)"
        ></el-avatar>
      </template>
      <template #item_user.nickname="{ row }">{{
        row.user ? (row.user.nickname || '--') : '--'
      }}</template>
      <template #item_user.phone="{ row }">{{
        row.user ? (row.user.phone || '--') : '--'
      }}</template>
      <template #item_user.title_name="{ row }">{{
        row.user ? (row.user.title_name || '--') : '--'
      }}</template>
      <template slot="item_price" slot-scope="{ row }">
        <div class="order_price">
          <div class="price">￥{{ row.price.toFixed(2) }}</div>
        </div>
      </template>

      <template slot="item_apply_refund" slot-scope="{ row }">
        <list-status-text
          :type="
            row.apply_refund == 1
              ? 'warning'
              : row.apply_refund == 2
              ? 'success'
              : row.apply_refund == 3
              ? 'danger'
              : 'info'
          "
          :text="row.apply_refund == 1 ? '待商家受理' : row.apply_refund_text"
        />
      </template>
      <template #top> </template>
      <template v-slot:operation="scope">
        <el-button
          v-if="scope.row.apply_refund == 1"
          type="text"
          size="small"
          @click="agreeRow(scope.row)"
          >同意</el-button
        >

        <el-button
          v-if="scope.row.apply_refund == 1"
          type="text"
          size="small"
          @click="refuseRow(scope.row)"
          >拒绝</el-button
        >
        <el-button type="text" size="small" @click="editRow(scope.row)"
          >详情</el-button
        >
      </template>
    </list-layout>
    <common-uncheck-dialog
      v-model="checkDialog.show"
      :edit-data="checkDialog.data"
      :complete="refreshList"
      :submitApi="uncheckRetailWithdraw"
    />
  </div>
</template>

<script>
import ListLayout from '@/base/layout/ListLayout'
import {
  checkRetailWithdraw,
  exportWithdrawCheckList,
  uncheckRetailWithdraw,
  withdrawCheckList,
} from '../api/examine'
import CommonUncheckDialog from '../../common/components/CommonUncheckDialog'
import UserWithdrawCheckListFilter from '../components/UserWithdrawCheckListFilter'

export default {
  components: {
    UserWithdrawCheckListFilter,
    CommonUncheckDialog,
    ListLayout
  },
  data() {
    return {
      tabArr: [
        { label: '全部', name: 'all' },
        { label: '待审核', name: 'checking', badge: 1 },
        { label: '通过', name: 'checked' },
        { label: '不通过', name: 'uncheck' },
      ],
      thead: [
        // { type: "selection" },
        {
          label: '会员ID',
          prop: 'user.id',
          minWidth: 100,
          tooltip: false,
          type: 'slot',
        },
        {
          label: '头像',
          prop: 'user.avatar',
          minWidth: 100,
          tooltip: false,
          type: 'slot',
        },
        {
          label: '会员名称',
          prop: 'user.nickname',
          minWidth: 100,
          tooltip: false,
          type: 'slot',
        },
        {
          label: '手机号码',
          prop: 'user.phone',
          minWidth: 120,
          tooltip: false,
          type: 'slot',
        },
        {
          label: '会内职务',
          prop: 'user.title_name',
          minWidth: 200,
          tooltip: false,
          type: 'slot',
        },
        {
          label: '提现金额',
          prop: 'price',
          minWidth: 140,
          sortable: true,
          type: 'slot',
        },
        {
          label: '状态',
          prop: 'status_text',
          minWidth: 120,
        },
        // {
        //   label: '不通过原因',
        //   prop: 'status_text',
        //   minWidth: 120,
        //   visible: (r, q) => q.tab === 'uncheck'
        // },
        {
          label: '提现时间',
          prop: 'create_time',
          minWidth: 200,
          sortable: true,
        },
        {
          type: 'operation',
          label: '操作',
          width: 120,
          operations: [
            { command: 'pass', text: '通过', disabled: (r) => r.status_text !== '审核中' },
            { command: 'no_pass', text: '不通过', disabled: (r) => r.status_text !== '审核中' },
          ],
        },
      ],
      // 筛选对象
      filterForm: {
        page: 1,
        page_size: 15,
        user_name: '',
        phone: '',
        status: -1,
        min_price: -1,
        max_price: -1,
        tab: 'all',
        positionIds: [],
        // order_type: 'create_time',
        // is_desc: 1
      },
      checkDialog: {
        show: false,
        data: [],
      },
    }
  },
  methods: {
    exportWithdrawCheckList,
    uncheckRetailWithdraw,
    getList(data) {
      return withdrawCheckList(data)
    },
    // 筛选回调
    ok(e) {
      this.filterForm = { ...this.filterForm, ...e, page: 1 }
      this.$refs.listLayout.getList(this.filterForm)
    },
    // 刷新列表
    refreshList() {
      this.$refs.listLayout.getList()
    },
    onCommand({ command, row }) {
      if (command === 'pass') {
        this.showConfirm(
          {
            content: '确定通过这条数据？',
          },
          () => {
            checkRetailWithdraw({ id: row.id })
              .then(({ code, data, msg }) => {
                if (code) {
                  this.$message.success(msg)
                  this.refreshList()
                }
              })
              .catch(() => {})
          }
        )
      } else if (command === 'no_pass') {
        this.checkDialog.data = row.id
        this.checkDialog.show = true
      }
    },
    /**
     * 操作确认框
     * @param {Object} obj            确认框配置参数
     * @param {Function} callback     点击确定回调函数
     */
    showConfirm(obj = {}, callback) {
      let _obj = obj || {}
      if (!_obj.content && callback) callback()
      this.$confirm(_obj.content, _obj.title || '提示', {
        confirmButtonText: _obj.confirmText || '确定',
        cancelButtonText: _obj.cancelText || '取消',
        type: _obj.type || 'info',
      })
        .then(() => {
          callback && callback()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.goods-name {
  display: flex;
  height: 80px;
  .left {
    margin-right: 10px;
  }

  .right {
    .goods-attr {
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .goods-specs {
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .more {
      el-button {
      }
    }
  }
}
.price_count {
  height: 80px;
  .price {
  }

  .count {
  }
}
.user {
  .item {
    display: flex;
    .left {
      min-width: 60px;
      margin-right: 5px;
    }

    .right {
      width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.order_price {
  .price {
    font-weight: 700;
  }

  .text {
    color: #b3b3b3;
    font-size: 12px;
  }
}
</style>
